import { Container, Grid, Link, useTheme } from '@material-ui/core'
import { LogoEyeIcon } from '../Common/icons'

export const Footer = () => {
  const theme = useTheme()
  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      style={{
        backgroundColor: theme.palette.primary.main,
        color: '#98A5B3',
        fontSize: '14px',
        paddingTop: '14px',
        paddingBottom: '16px'
      }}
    >
      <Container maxWidth={'lg'}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid container item xs={12}>
            <LogoEyeIcon styles={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '8px' }} />
            &nbsp;©&nbsp;2018-
            {new Date().getFullYear()}&nbsp;iSky Research&nbsp;|&nbsp;{APP_VERSION}&nbsp;|&nbsp;
            <Link
              target="_blank"
              href="https://iskyresearch.com/terms-and-conditions"
              style={{ color: theme.palette.navigation.dark }}
            >
              Privacy & Terms
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
